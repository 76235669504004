export const toAbsoluteUrl = (pathname: string) =>
  process.env.PUBLIC_URL + pathname;

export const toAbsoluteUrlENV = (pathname: string) => {
  if (pathname.includes("http" || "")) return pathname;
  else {
    if (pathname.charAt(0) === "/") {
      pathname = pathname.substring(1);
    }
    return `${process.env.REACT_APP_IMAGE_URL}/${pathname}`;
  }
};
