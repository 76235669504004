import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

const PrivateRoutes = () => {
  const UserManagementModule = lazy(
    () => import("app/_modules/user-management")
  );

  const OperatorManagement = lazy(() => import("app/_modules/operator"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />

        {/*  <Route path="contents/*" element={<ContentsPage />} /> */}

        <Route
          path="operator/*"
          element={
            <SuspensedView>
              <OperatorManagement />
            </SuspensedView>
          }
        />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UserManagementModule />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/operator" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
