/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from "app/_modules/auth";
import { useIntl } from "react-intl";
import { PERMISSIONS_MAP } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { myPermission, allPermission } = useAuth();

  const hasPermission = (permissionName: any) => {
    const userPermission = myPermission.find(
      (perm: any) => perm.name === permissionName
    );

    const allPermissionArray = allPermission.find(
      (perm: any) => perm.name === permissionName
    );

    return (
      userPermission &&
      allPermissionArray &&
      // eslint-disable-next-line eqeqeq
      userPermission.id == allPermissionArray.id
    );
  };

  const canViewUserManagement =
    hasPermission(PERMISSIONS_MAP.USER_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_DELETED) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_LOG);

  const canViewAdminManagement =
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN) ||
    hasPermission(PERMISSIONS_MAP.LIST_ROLE) ||
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN_LOG);

  const canViewContentManagement =
    hasPermission(PERMISSIONS_MAP.CONTENT_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_VIDEO) ||
    hasPermission(PERMISSIONS_MAP.LIST_POST) ||
    hasPermission(PERMISSIONS_MAP.LIST_SHARE) ||
    hasPermission(PERMISSIONS_MAP.LIST_HASHTAG);

  const canViewAlarmManagement =
    hasPermission(PERMISSIONS_MAP.LIST_PUSH) ||
    hasPermission(PERMISSIONS_MAP.LIST_EVENT) ||
    hasPermission(PERMISSIONS_MAP.LIST_NOTICE);

  const canViewSettingManagement =
    hasPermission(PERMISSIONS_MAP.LIST_AD_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_APP_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_RANKING_SETTING) ||
    hasPermission(PERMISSIONS_MAP.LIST_TERM) ||
    hasPermission(PERMISSIONS_MAP.LIST_LOCALIZATION_SETTING);

  return (
    <>
      {/* <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      /> */}
      <SidebarMenuItem
        to="/operator"
        icon="profile-circle"
        title={intl.formatMessage({ id: "OPERATOR-LIST" })}
        fontIcon="bi-app-indicator"
      />

      <SidebarMenuItem
        to="/user-management/fake"
        title={intl.formatMessage({ id: "FAKE-LIST" })}
        icon="profile-user"
      />
    </>
  );
};

export { SidebarMenuMain };
