import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/auth/partner-login`;
export const VERIFY_PASSWORD_URL = `${API_URL}/admin/auth/forgot-password/verify`;
export const REQUEST_PASSWORD_URL = `${API_URL}/admin/auth/forgot-password/request`;
export const GET_CURRENT_PERMISSION_URL = `${API_URL}/role-permissions/current`;
export const GET_ALL_PERMISSION_URL = `${API_URL}/role-permissions`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    username: email,
    password,
  });
}

// Server should return AuthModel
export function verifyNewPassword(
  email: string,
  code: string,
  password: string
) {
  return axios.post(VERIFY_PASSWORD_URL, {
    email,
    code,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get(REQUEST_PASSWORD_URL, {
    params: { email },
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}

export function getMyPermissions() {
  return axios.get(`${GET_CURRENT_PERMISSION_URL}`);
}

export function getAllAppPermissions() {
  return axios.get(`${GET_ALL_PERMISSION_URL}`);
}
