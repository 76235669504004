import { Link } from "react-router-dom";
import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { MutableRefObject, useEffect, useRef } from "react";
import { ToggleComponent } from "../../../assets/ts/components";
import { useIntl } from "react-intl";

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>;
};

const SidebarLogo = (props: PropsType) => {
  const intl = useIntl();

  const { config } = useLayout();
  const toggleRef = useRef<HTMLDivElement>(null);

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
    ? "minimize"
    : "";
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(
        toggleRef.current!
      ) as ToggleComponent | null;

      if (toggleObj === null) {
        return;
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on("kt.toggle.change", function () {
        // Set animation state
        props.sidebarRef.current!.classList.add("animating");

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove("animating");
        }, 300);
      });
    }, 600);
  }, [toggleRef, props.sidebarRef]);

  const imageSrc = toAbsoluteUrl("/media/logos/rightNow.png");
  const wishnote_icon = toAbsoluteUrl("/media/logos/rightNow_small.png");

  return (
    <div
      className="app-sidebar-logo px-6"
      id="kt_app_sidebar_logo"
      style={{
        display: "flex",
        justifyContent: "center",
        background: "white",
        height: `var(--bs-app-header-height)`,
        alignItems: "center",
      }}
    >
      <Link to="/operator">
        {config.layoutType === "dark-sidebar" ? (
          <>
            <h1
              className="h-48px mt-2  app-sidebar-logo-default"
              style={{ color: "#F1416C" }}
            >
              {/*  {intl.formatMessage({ id: "APP-NAME" })} */}

              <div className="d-flex  align-items-center">
                {/* begin:: Avatar */}

                <img src={imageSrc} className="w-150px" />
              </div>
            </h1>
            {/* <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/default-dark.svg")}
              className="h-25px app-sidebar-logo-default"
            /> */}
          </>
        ) : (
          <>
            <h1 className="h-25px app-sidebar-logo-default">
              {" "}
              {intl.formatMessage({ id: "APP-NAME" })}
            </h1>
            {/* <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/default.svg")}
              className="h-25px app-sidebar-logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/default-dark.svg")}
              className="h-25px app-sidebar-logo-default theme-dark-show"
            /> */}
          </>
        )}

        {/* <img
          alt="Logo"
          src={toAbsoluteUrl("/media/logos/default-small.svg")}
          className="h-20px app-sidebar-logo-minimize"
        /> */}

        <h1
          className="h-20px app-sidebar-logo-minimize"
          style={{ color: "#F1416C" }}
        >
          <div className="d-flex align-items-center">
            {/* begin:: Avatar */}

            <img src={wishnote_icon} className="w-40px" />
          </div>
        </h1>
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled ||
        appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id="kt_app_sidebar_toggle"
          className={clsx(
            "app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate",
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle="true"
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target="body"
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName="double-left" className="fs-2 rotate-180" />
        </div>
      )}
    </div>
  );
};

export { SidebarLogo };
